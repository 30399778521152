import {
  getToken as hasToken,
  isChangePasswordByWindowLocation,
  isOrderTrackerByWindowLocation
} from "@/plugins/auth";
import store from "@/store";

/**
 * Valida se existe token e se a rota precisa de autenticação
 * @param {object} to
 * @returns {boolean}
 */
const routeNeedsAuth = (to) => {
  return !hasToken() && to.meta.requiresAuth;
};

/**
 * Valida se a rota atual do Vue router é a "/" Dashboard
 * @param {object} to
 * @returns {boolean}
 */
const isDashboardRoute = (to) => {
  return to.fullPath === "/";
};

const beforeEach = (to) => {
  /**
   * Percorre o array de rotas correspondentes do último para o primeiro
   * buscando a rota mais proxima que possui um titulo
   *
   * Ex. Se temos /alguma/rota/profunda/aninhada e /alguma, /profunda e /aninhada possui títulos
   * /aninhada será a escolhida
   * @param {object} routes
   */
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((route) => route.meta && route.meta.title);
  if (nearestWithTitle) {
    /**
     * Caso o titulo seja uma função, o titulo será definido dinamicamente
     * de acordo com os parametros da rota
     *
     * veja: /app/Orders/routes/index.js
     */
    const companyName = store.getters.storeInfo.name
      ? `${store.getters.storeInfo.name} |`
      : "";
    const pageTitle =
      typeof nearestWithTitle.meta.title === "function"
        ? nearestWithTitle.meta.title(to)
        : nearestWithTitle.meta.title;

    document.title = `${companyName} ${pageTitle}`;
  }

  if (routeNeedsAuth(to)) {
    const urlBaseDir = process.env.VUE_APP_BASEDIR;
    let routeToRedirect = `${urlBaseDir}login?origem=central`;
    /**
     * Em produção o usuario é redirecionado para o login fora da central
     */
    if (process.env.NODE_ENV === "production") {
      const {isChangePasswordRoute, route} =
        isChangePasswordByWindowLocation();

      if (isDashboardRoute(to) && isChangePasswordRoute) {
        routeToRedirect = route;
      }

      const {isOrderTrackerRoute, route: orderTrackerRoute} =
        isOrderTrackerByWindowLocation();
      if (isDashboardRoute(to) && isOrderTrackerRoute) {
        const url = new URL(orderTrackerRoute);
        url.search = '';
        routeToRedirect = url.search;
      }

      window.location.replace(routeToRedirect);
    }
  }
};

export default beforeEach;
