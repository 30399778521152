import {
  setToken as httpSetToken,
  revokeToken as httpRevokeToken,
  http,
} from "@/plugins/http";

/**
 * Utiliza o plugin /http para revogar o token no HEADER da aplicação e fazer logout.
 * @returns {void}
 */
export function logout() {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("hasPlataformToken");
  httpRevokeToken();
}

/**
 * Retorna o valor do parametro desejado caso exista na URL
 * @param {String} queryParam, parametro da query string
 * @return {String | Boolean} Valor do parametro caso exista, false caso contrário
 */
export function getQueryParam(path, queryParam) {
  const url = path.split("?");
  if (url.length <= 1) {
    return false;
  }

  const urlParams = url[url.length - 1].split("&");
  for (let i = 0; i < urlParams.length; i += 1) {
    const paramValue = urlParams[i].split("=");

    if (paramValue[0] === queryParam) {
      return paramValue[1];
    }
  }

  return false;
}

/**
 * Verifica se existe um token disponível
 * @returns {String | Boolean } Valor do token caso exista, false caso contrário
 */
export function getToken() {
  const urlToken = getQueryParam(window.location.href, "token");
  if (urlToken) {
    return urlToken;
  }

  let localToken = localStorage.getItem("jwtToken");
  if (localToken) {
    return localToken;
  }

  localStorage.removeItem("hasPlataformToken");
  return false;
}

/**
 * Utiliza o plugin /http para setar o token no HEADER da aplicação
 * @returns {Boolean} Caso exista o token retorna true, false caso contrário
 */
export function setToken(token) {
  if (!token) {
    return false;
  }

  localStorage.setItem("jwtToken", token);
  httpSetToken(token);
  return true;
}

/**
 * Valida se a rota atual está na lista de urls que precisam de autenticação
 * @returns {bool}
 */
export function currentUrlNeedsAuthentication() {
  // Lista de rotas que não precisam de autenticação
  const routesWithoutAuthentication = [
    /my-account\/mail-password-change/,
    /my-account\/tracker/,
    /my-account\/login/,
  ];

  return !routesWithoutAuthentication.find((route) =>
    route.test(window.location.pathname)
  );
}

/**
 * Busca o ID da loja nos parametros da URL
 * Caso não encontre, busca no localstorage
 * e se não encontrar em ambos, redireciona para a loja
 * @returns {string}
 */
export function storeId() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const store_id = params.loja;
  if (store_id) {
    localStorage.setItem("storeId", store_id);
    return store_id;
  }

  const localStoreId = localStorage.getItem("storeId");
  if (localStoreId) {
    return localStoreId;
  }

  if (/my-account\/tracker/.test(window.location.pathname)) {
    return window.location.pathname.split("/")[3];
  }

  return null;
}

/**
 * Retorna o end-point que a função authorize deve usar
 * @returns {string}
 */
function storeUrl() {
  /**
   * Retorna o end-point que precisa de autenticação
   */
  if (currentUrlNeedsAuthentication()) {
    return "/store?include=customer";
  }

  /**
   * Retorna o end-point sem autenticação
   */
  return `/store-basic-info`;
}

/**
 * Autoriza o token e retorna os dados da loja
 * @returns {Promisse}
 */
export default function authorize() {
  setToken(getToken());
  return http.get(storeUrl());
}

/**
 * Retorna se a url que esta acessando a loja é segura
 * @returns {Promise}
 */
export function isSecureDomain() {
  let validateParams = {};

  const storeIdFromPlataformCookie =
    localStorage.getItem(`storeIdFromPlataform`);

  if (storeIdFromPlataformCookie) {
    validateParams = { store_id: storeIdFromPlataformCookie };
  }

  return http.get(`/domain-validate`, { params: validateParams });
}

/**
 * Adiciona o id da loja salvo no cookie no localstorage.
 * (storeIdFromPlataform)
 */
export function putStoreIdCookieOnLocalStorage() {
  try {
    const cookie = document.cookie.match(/LOJA=([0-9]+)/);
    if (cookie) {
      localStorage.setItem(`storeIdFromPlataform`, cookie[1]);
    }
  } catch (e) {
    console.info(e);
  }
}

/**
 * Retorna a rota atual do navegador em que a aplicação está sendo executada.
 *
 * Estou utilizando o window.location para obter a rota pois o erro pode
 * estar ocorrendo antes da chamada a store-basic-info que tras o id da loja
 *
 * @returns {string}
 */
function getCurrentRouteByWindowLocation() {
  return window.location.href;
}

/**
 * Verifica se a rota atual do navegador contém a string "mail-password-change"
 * @returns {object}
 */
export function isChangePasswordByWindowLocation() {
  const route = getCurrentRouteByWindowLocation();
  const isChangePasswordRoute = route.includes("mail-password-change");

  return {
    isChangePasswordRoute,
    route,
  };
}

/**
 * Verifica se a rota atual do navegador contém a string "tracker/<store_id>/<codigo_acesso>"
 * @return {{route: string, isOrderTrackerRoute: boolean}}
 */
export function isOrderTrackerByWindowLocation() {
  const route = getCurrentRouteByWindowLocation();
  const isOrderTrackerRoute = /tracker\/\d+\/[A-Za-z\d]+/
    .test(window.location.href)

  return {
    isOrderTrackerRoute,
    route,
  };
}
