import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import { routes as app } from "@/domains";
import beforeEach from "./beforeEach";

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
const routes = [...app];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASEDIR),
  routes: routes,
  linkActiveClass: "active-page",
  linkExactActiveClass: "current-page",
});

router.beforeEach(beforeEach);

trackRouter(router, {
  template(to) {
    return {
      page_title: document.title,
      page_path: to.path,
    };
  },
});

export default router;
